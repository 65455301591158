import {
  tableState,
  tableMutations,
  tableActions,
  tableGetters
} from "@tt/vue-components";
import EntityService from "@/services/EntityService";
import InventoryService from "@/services/InventoryService";
import isBlacklistedFilter from "@/store/modules/skus/filters/isBlacklistedFilter";
import skuFilter from "@/store/modules/skus/filters/skuFilter";
import nameFilter from "@/store/modules/skus/filters/nameFilter";
import productNumberFilter from "@/store/modules/skus/filters/productNumberFilter";
import brandFilter from "@/store/modules/skus/filters/brandFilter";
import seasonFilter from "@/store/modules/skus/filters/seasonFilter";
import isDeletedFilter from "@/store/modules/skus/filters/isDeletedFilter";
import customFieldFilter from "@/store/modules/skus/filters/customFieldFilter";
import locations from "@/store/modules/skus/locations";
import stockMutations from "@/store/modules/skus/stockMutations";
import virtualStockDetails from "@/store/modules/skus/virtualStockDetails";
import allocations from "@/store/modules/skus/allocations";
import reservations from "@/store/modules/skus/reservations";

const skuOverview = {
  requestController: null,
  namespaced: true,
  modules: {
    skuFilter,
    nameFilter,
    productNumberFilter,
    brandFilter,
    seasonFilter,
    isDeletedFilter,
    isBlacklistedFilter,
    customFieldFilter,
    locations,
    stockMutations,
    virtualStockDetails,
    allocations,
    reservations,
  },
  state: {
    ...tableState,
    sku: null,
    skuDetails: null,
  },
  mutations: {
    ...tableMutations,
    SET_SKU(state, sku) {
      state.sku = sku;
    },
    SET_SKU_DETAILS(state, sku) {
      state.skuDetails = sku;
    }
  },
  actions: {
    ...tableActions,
    fetchItems({ commit, state }, refresh = false) {
      if (refresh || state.items.length === 0) {
        commit("SET_ERROR", false);
        commit("SET_LOADING", true);

        const params = {
          pagination: state.pagination,
          sorting: state.sorting,
          search: state.search
        };

        if (this.requestController) {
          this.requestController.abort();
        }
        this.requestController = new AbortController();

        InventoryService.skus.list(params, { signal: this.requestController.signal })
          .then(json => {
            if (json) {
              commit("SET_ITEMS", json);
            }
          })
          .catch(err => {
            commit("SET_ERROR", true);
            console.log(err);
          })
          .finally(() => {
            commit("SET_LOADING", false);
          });
      }
    },
    fetchBySku(context, sku) {
      return InventoryService.skus.get(sku)
        .then(json => {
          console.log(json)
        context.commit("SET_ITEM", json);
      })
      .catch(err => {
        context.commit("SET_ITEM", null);
        console.log(err);
      })
      .finally(() => {
        context.commit("SET_LOADING", false);
      });
    },
    selectSku({commit, dispatch}, item) {
      if (item !== null) {
        commit("SET_SKU", item);
        commit("SET_ITEM", item);
        dispatch("fetchDetails");
        dispatch("locations/fetchItems", true);
        dispatch("virtualStockDetails/fetchItems", true);
        dispatch("stockMutations/fetchItems", true);
        dispatch("allocations/fetchItems", true);
      } else {
        commit("SET_SKU", null);
        commit("SET_ITEM", null);
        commit("SET_SKU_DETAILS", null);
      }
    },
    activate(context, sku) {
      return new Promise((resolve, reject) => {
        InventoryService.generic.delete(sku.blacklistSku)
          .then(() => {
            sku.blacklistSku = null;
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    blacklist(context, sku) {
      return new Promise((resolve, reject) => {
        InventoryService.blacklist.create({
          sku: sku["@id"],
          source: null,
          ttlDt: null
        })
          .then(json => {
            sku.blacklistSku = json["@id"];
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject(err["hydra:description"]);
          });
      });
    },
    fetchDetails({commit, state}) {
      commit("SET_LOADING", true);
      EntityService.sku.get(state.sku.sku)
        .then(json => {
          commit("SET_SKU_DETAILS", json);
          commit("SET_LOADING", false);
        })
        .catch(err => {
          commit("SET_SKU_DETAILS", null);
          commit("SET_ERROR", true);
          commit("SET_LOADING", false);
          console.log(err);
        });
    }
  },
  getters: {
    ...tableGetters
  }
};

export default skuOverview;
